define("discourse/plugins/discourse-ebay-ads/discourse/templates/components/ebay-preferences-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "ebay_ads.preferences.title"}}</h1>
  
  <div class="control-group">
    <label class="control-label">{{i18n "ebay_ads.preferences.ebay_username"}}</label>
    <div class="controls">
      <label class="text-label">
        <Input
          @type="text"
          class="input-xxlarge"
          @value={{this.ebayUsername}}
        />
      </label>
      <a href="https://www.ebay.com/usr/{{this.ebayUsername}}" target="_blank">
        {{d-icon 'link'}} https://www.ebay.com/usr/{{this.ebayUsername}}
      </a>
  
      <div class="controls">
        <label class="checkbox-label">
          <Input
            @type="checkbox"
            @checked={{this.hideListings}}
          />
          {{i18n "ebay_ads.preferences.hide_listings"}}
        </label>
      </div>
  
    </div>
  </div>
  
  */
  {
    "id": "xvQ0/NTm",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[\"ebay_ads.preferences.title\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"ebay_ads.preferences.ebay_username\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"text-label\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,0,\"input-xxlarge\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"ebayUsername\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[29,[\"https://www.ebay.com/usr/\",[30,0,[\"ebayUsername\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"link\"],null]],[1,\" https://www.ebay.com/usr/\"],[1,[30,0,[\"ebayUsername\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"hideListings\"]]]],null],[1,\"\\n        \"],[1,[28,[35,1],[\"ebay_ads.preferences.hide_listings\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"h1\",\"i18n\",\"div\",\"label\",\"input\",\"a\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-ebay-ads/discourse/templates/components/ebay-preferences-settings.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-discord-datastore/discourse/templates/components/discord-header", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.discord_header_image }}
    <img src="{{ siteSettings.discord_header_image }}" alt="header_image">
  {{else}}
    <h1>{{d-icon 'fab-discord'}}{{" "}}{{ i18n 'discord_datastore.user_title' }}</h1>
  {{/if}}
  */
  {
    "id": "B+soj6xk",
    "block": "[[[41,[30,0,[\"siteSettings\",\"discord_header_image\"]],[[[1,\"  \"],[44,[[28,[37,2],null,[[\"siteSettings\"],[[28,[32,0],[\"siteSettings\"],null]]]]],[[[10,\"img\"],[15,\"src\",[29,[[52,[30,1,[\"siteSettings\"]],[28,[30,1,[\"siteSettings\"]],null,null],[28,[32,1],[[30,0],\"siteSettings.discord_header_image\",\"[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-discord-datastore/discourse/templates/components/discord-header.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,\"alt\",\"header_image\"],[12],[13]],[1]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"h1\"],[12],[1,[28,[35,5],[\"fab-discord\"],null]],[1,\" \"],[1,[28,[35,6],[\"discord_datastore.user_title\"],null]],[13],[1,\"\\n\"]],[]]],[1,[28,[32,2],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-discord-datastore/discourse/templates/components/discord-header.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\",\"img\",\"h1\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-discord-datastore/discourse/templates/components/discord-header.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});